import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import { SliderChangeEvent } from 'primeng/slider'

@Component({
	selector: 'ms-range',
	host: {
		class: 'range',
	},
	template: `
		<ms-control class="range-value" [validate]="false">
			<ms-visually-hidden>Min</ms-visually-hidden>
			<input
				type="number"
				[(ngModel)]="minValue"
				(blur)="onBlur($event)"
				(keydown)="onKeydown($event)"
			/>
		</ms-control>

		<p-slider
			[max]="max"
			[min]="min"
			[range]="true"
			[step]="step"
			[(ngModel)]="values"
			(onChange)="onSliderChange($event)"
		></p-slider>

		<ms-control class="range-value" [validate]="false">
			<ms-visually-hidden>Max</ms-visually-hidden>
			<input
				type="number"
				[(ngModel)]="maxValue"
				(blur)="onBlur($event)"
				(keydown)="onKeydown($event)"
			/>
		</ms-control>
	`,
	styleUrls: ['./range.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeComponent extends BaseComponent implements OnInit {
	public minValue: number

	public maxValue: number

	@Input()
	public min = 0

	@Input()
	public max = 100

	@Input()
	public step = 1

	@Input()
	public values: [number, number] = [this.min, this.max]

	@Output()
	public valuesChange = new EventEmitter<[number, number]>()

	public ngOnInit() {
		this.minValue = this.values[0]
		this.maxValue = this.values[1]
	}

	public onBlur(e: Event) {
		this._validateInputs()
	}

	public onKeydown(e: KeyboardEvent) {
		if (e.key === 'Enter') {
			this._validateInputs()
		}
	}

	public onSliderChange(e: SliderChangeEvent) {
		this.minValue = this.values[0]
		this.maxValue = this.values[1]
		this.valuesChange.emit(this.values)
	}

	private _validateInputs() {
		if (this.minValue < this.min || this.minValue > this.max) {
			this.minValue = this.min
		}

		if (this.maxValue > this.max || this.maxValue < this.min) {
			this.maxValue = this.max
		}

		if (this.maxValue < this.minValue) {
			this.maxValue = this.minValue
		}

		if (this.minValue > this.maxValue) {
			this.minValue = this.maxValue
		}

		this.values = [this.minValue, this.maxValue]
		this.valuesChange.emit(this.values)
	}
}
