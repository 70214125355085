import { ChartOptions } from 'chart.js'

export const CHART_DEFAULT_OPTIONS: ChartOptions = {
	animation: {
		duration: 0,
	},
	datasets: {
		line: {
			pointRadius: 4,
			pointHoverRadius: 4,
		},
	},
	interaction: {
		axis: 'x',
		intersect: false,
		mode: 'nearest',
	},
	maintainAspectRatio: false,
	plugins: {
		backgroundColor: {
			color: '#ffffff',
		},
		datalabels: {
			align: 'top',
			clamp: true,
			font: {
				size: 10,
			},
		},
	} as any,
	responsive: true,
	scales: {
		x: {
			grid: {
				display: true,
			},
			ticks: {
				font: {
					size: 10,
				},
				maxRotation: 30,
				minRotation: 30,
				padding: 5,
			},
		},
		y: {
			grid: {
				display: true,
			},
			ticks: {
				font: {
					size: 10,
				},
				padding: 12,
			},
		},
	},
}
