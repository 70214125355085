import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-stat',
	host: {
		class: 'stat',
	},
	template: `
		<div class="stat__value">
			{{ stat.value }}
			<!-- <small class="stat__unit" *ngIf="stat.unit">{{ stat.unit }}</small> -->
		</div>
		<div class="stat__label">{{ stat.label }}</div>
	`,
	styleUrls: ['./stat.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatComponent extends BaseComponent {
	@Input()
	public stat: Stat
}
