import { TableService } from '../../services/table.service'
import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FormatService } from '@services/format.service'
import { isNumeric } from '@utils/is-numeric'
import { get } from 'lodash-es'

export type TableStatCalc = 'sum' | 'avg' | 'min' | 'max' | 'rng' | 'cnt'

@Component({
	selector: 'ms-table-stat',
	template: `
		@if (isValidFormat) {
			{{ value | number: '1.0-2' }}
		}
	`,
	styleUrls: ['./table-stat.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableStatComponent extends BaseComponent implements OnInit {
	@Input()
	public calc: TableStatCalc = 'avg'

	@Input()
	public field?: MS.Table.Column['field']

	@Input()
	public type?: MS.Table.Column['type']

	@Input()
	public valueFn?: (row: any) => number

	// Computed Properties
	// ----------------------------------------

	public get pTable() {
		return this.tableService.pTable
	}

	public get rows() {
		return this.tableService.rows
	}

	public get values() {
		const rows = this.rows

		if (!rows || !rows.length) {
			return null
		}

		if (typeof this.valueFn === 'function') {
			return rows.map((row) => this.valueFn!(row))
		}

		if (this.field) {
			return rows
				.map((row) => {
					return Number(get(row, this.field as string))
				})
				.filter((v) => {
					return isNumeric(v)
				})
		}

		return null
	}

	public get value() {
		const values = this.values

		if (!values?.length) return null

		switch (this.calc) {
			case 'sum':
				return this._format(values.reduce((a, b) => a + b, 0))

			case 'avg':
				return this._format(values.reduce((a, b) => a + b, 0) / values.length)

			case 'min':
				return this._format(Math.min(...values))

			case 'max':
				return this._format(Math.max(...values))

			case 'rng':
				return `${this._format(Math.min(...values))} - ${this._format(
					Math.max(...values),
				)}`

			case 'cnt':
				return values.length

			default:
				return null
		}
	}

	public get isValidFormat() {
		return this.type && ['currency', 'number', 'percent'].includes(this.type)
	}

	// Lifecycle Methods
	// ----------------------------------------

	public constructor(
		private _formatService: FormatService,
		public tableService: TableService,
	) {
		super()
	}

	public ngOnInit() {
		this.pTable?.onFilter
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.cdRef.markForCheck())

		this.pTable?.tableService.valueSource$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.cdRef.markForCheck())
	}

	// Private Methods
	// ----------------------------------------

	private _format(value: number) {
		return this._formatService.format(value, this.type)
	}
}
