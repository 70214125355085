import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-main-layout',
	host: {
		class: 'layout',
	},
	template: `
		<aside class="layout__aside">
			<ms-menu></ms-menu>
		</aside>

		<main class="layout__main">
			<div class="layout__inner">
				<div class="layout__header">
					<ms-header></ms-header>
				</div>

				<div class="layout__content">
					<router-outlet></router-outlet>
				</div>
			</div>
		</main>
	`,
	styleUrls: ['./main.layout.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayout extends BaseComponent {}
