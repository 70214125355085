import { EmbedRatio } from '../../types'
import { BaseComponent } from '@abstract/base.component'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	Input,
	OnInit,
	QueryList,
	TemplateRef,
	ViewEncapsulation,
} from '@angular/core'
import { NamedTemplateDirective } from '@directives/named-template/named-template.directive'

@Component({
	selector: 'ms-embed',
	host: {
		class: 'embed',
	},
	template: `
		@if (loading) {
			<ms-progress-spinner class="embed__spinner"></ms-progress-spinner>
		}

		<ng-content select="[embed-item]"></ng-content>

		@if (tempOverlay) {
			<div class="embed__overlay">
				<ng-container [ngTemplateOutlet]="tempOverlay" />
			</div>
		}
	`,
	styleUrls: ['./embed.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedComponent
	extends BaseComponent
	implements OnInit, AfterContentInit
{
	@Input()
	public ratio?: EmbedRatio

	@Input()
	public loading = false

	public tempOverlay?: TemplateRef<any>

	@ContentChildren(NamedTemplateDirective, { descendants: false })
	private _templates: QueryList<NamedTemplateDirective>

	// Lifecycle Methods
	// ----------------------------------------

	public ngOnInit() {
		this._setClasses()
	}

	public ngAfterContentInit() {
		this._templates.forEach((item) => {
			switch (item.name) {
				case 'overlay':
					this.tempOverlay = item.template
					break
			}
		})
	}

	// Private Methods
	// ----------------------------------------

	private _setClasses() {
		if (this.ratio) {
			this.el.style.setProperty(
				'--embed-ratio',
				`${this.ratio.replace(':', '/')}`,
			)
		}
	}
}
