import { StatComponent } from './components/stat/stat.component'
import { StatsBarComponent } from './components/stats-bar/stats-bar.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

const components = [StatComponent, StatsBarComponent]

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [CommonModule],
})
export class StatsModule {}
