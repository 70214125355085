import { Chart, Plugin } from 'chart.js'

export const backgroundColor: Plugin = {
	id: 'backgroundColor',
	beforeDraw: (chart: Chart, args: any, options: any) => {
		const { ctx } = chart
		ctx.save()
		ctx.globalCompositeOperation = 'destination-over'
		ctx.fillStyle = options.color || 'transparent'
		ctx.fillRect(0, 0, chart.width, chart.height)
		ctx.restore()
	},
}
