import { BaseComponent } from '../../../../abstract/base.component'
import { CHART_DEFAULT_OPTIONS } from '../../constants'
import { backgroundColor } from '../../plugins'
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'
import { EmbedRatio } from '@components/embed/types'
import {
	Chart,
	ChartConfiguration,
	ChartData,
	ChartOptions,
	ChartType,
} from 'chart.js'
import { cloneDeep, merge } from 'lodash-es'

@Component({
	selector: 'ms-chart',
	host: {
		class: 'chart',
	},
	template: `
		<ms-embed [loading]="!chart" [ratio]="chartRatio">
			<div embed-item>
				@if (!hasData) {
					<div class="chart__no-data">No Data</div>
				}
				<canvas #canvas height="100%" width="100%" [hidden]="!hasData"></canvas>
			</div>
		</ms-embed>
	`,
	styleUrls: ['./chart.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent
	extends BaseComponent
	implements AfterViewInit, OnDestroy, OnChanges
{
	@ViewChild('canvas')
	private _canvas: ElementRef<HTMLCanvasElement>

	private _config: ChartConfiguration = {
		type: 'line',
		data: {
			datasets: [],
			labels: [],
		},
		options: cloneDeep(CHART_DEFAULT_OPTIONS),
	}

	public chart?: Chart | null

	@Input()
	public chartRatio?: EmbedRatio

	@Input()
	public chartData: ChartData['datasets'] = []

	@Input()
	public chartLabels: ChartData['labels'] = []

	@Input()
	public chartOptions = this._config.options

	@Input()
	public chartType = this._config.type

	// Computed Properties
	// ----------------------------------------

	public get hasData() {
		return this._config.data.datasets?.some(
			(dataset) => dataset.data?.length > 0,
		)
	}

	// Lifecycle Methods
	// ----------------------------------------

	public ngAfterViewInit() {
		this._initChart()
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.chartData) {
			this._config.data.datasets = changes.chartData.currentValue
		}

		if (changes.chartLabels) {
			this._config.data.labels = changes.chartLabels.currentValue
		}

		if (changes.chartOptions) {
			this._config.options = merge(
				this._config.options,
				changes.chartOptions.currentValue,
			)
		}

		if (changes.chartType) {
			this._config.type = changes.chartType.currentValue
		}

		this.update()
	}

	public ngOnDestroy() {
		this.chart?.destroy()
		this.chart = null
	}

	// Public Methods
	// ----------------------------------------

	public saveAsImage() {
		if (!this.chart) return

		const link = document.createElement('a')
		link.download = 'chart.png'
		link.href = this.chart.toBase64Image()
		link.click()
	}

	public update() {
		this.chart?.update()
	}

	public resize() {
		this.chart?.resize(this.el.offsetWidth, this.el.offsetHeight)
	}

	// Private Methods
	// ----------------------------------------

	private async _initChart() {
		const [{ Chart, registerables }, ChartAnnotation, ChartDataLabels] =
			await Promise.all([
				import('chart.js'),
				import('chartjs-plugin-annotation'),
				import('chartjs-plugin-datalabels'),
				import('chartjs-adapter-date-fns'),
			])

		Chart.register(...registerables)
		Chart.register(ChartAnnotation.default)
		Chart.register(ChartDataLabels.default)
		Chart.register(backgroundColor)

		this.chart = new Chart(this._canvas.nativeElement, this._config)
		this.cdRef.markForCheck()
	}
}
