import { NgModule } from '@angular/core'
import { FilterByPipe } from '@pipes/filter-by.pipe'
import { FormatPipe } from '@pipes/format.pipe'
import { GetMonthByIndexPipe } from '@pipes/get-month-by-index.pipe'
import { GetPipe } from '@pipes/get.pipe'
import { GroupByPipe } from '@pipes/group-by.pipe'
import { I18nDatePipe } from '@pipes/i18n-date.pipe'
import { ObsWithStatusPipe } from '@pipes/obs-with-status.pipe'
import { PadStartPipe } from '@pipes/pad-start.pipe'
import { SafeHtmlPipe } from '@pipes/safe-html.pipe'
import { SafeUrlPipe } from '@pipes/safe-url.pipe'
import { SortByPipe } from '@pipes/sort-by.pipe'
import { SortPipe } from '@pipes/sort.pipe'
import { TypeofPipe } from '@pipes/typeof.pipe'

const pipes = [
	FilterByPipe,
	FormatPipe,
	GetMonthByIndexPipe,
	GetPipe,
	GroupByPipe,
	I18nDatePipe,
	ObsWithStatusPipe,
	PadStartPipe,
	SafeHtmlPipe,
	SafeUrlPipe,
	SortByPipe,
	SortPipe,
	TypeofPipe,
]

@NgModule({
	imports: pipes,
	exports: pipes,
})
export class PipesModule {}
