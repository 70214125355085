import { BaseComponent } from '@abstract/base.component'
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core'

@Component({
	selector: 'ms-stats-bar',
	host: {
		class: 'stats-bar',
	},
	template: `
		@for (stat of stats; track trackBy($index, stat)) {
			<ms-stat [stat]="stat"></ms-stat>
		}
	`,
	styleUrls: ['./stats-bar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsBarComponent extends BaseComponent {
	@Input()
	public stats: Stat[] = []

	public trackBy(index: number, stat: Stat) {
		return index
	}
}
